@import "~react-image-gallery/styles/css/image-gallery.css"; /* montserrat-regular - cyrillic_latin */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/montserrat-v25-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v25-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v25-cyrillic_latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-italic - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-cyrillic_latin-italic.eot'); /* IE9 Compat Modes */
  src: url('./fonts/montserrat-v25-cyrillic_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v25-cyrillic_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v25-cyrillic_latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat-v25-cyrillic_latin-500.eot'); /* IE9 Compat Modes */
  src: url('./fonts/montserrat-v25-cyrillic_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v25-cyrillic_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v25-cyrillic_latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500italic - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/montserrat-v25-cyrillic_latin-500italic.eot'); /* IE9 Compat Modes */
  src: url('./fonts/montserrat-v25-cyrillic_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v25-cyrillic_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v25-cyrillic_latin-500italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat-v25-cyrillic_latin-700.eot'); /* IE9 Compat Modes */
  src: url('./fonts/montserrat-v25-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v25-cyrillic_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v25-cyrillic_latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700italic - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/montserrat-v25-cyrillic_latin-700italic.eot'); /* IE9 Compat Modes */
  src: url('./fonts/montserrat-v25-cyrillic_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v25-cyrillic_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v25-cyrillic_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v25-cyrillic_latin-700italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
}

p, li, ul, ol{
    margin: 0;
    padding: 0;
}

input{
    border: none;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

input::placeholder {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

button {
    border: none;
    outline: none;
    font-family: Montserrat, sans-serif;
    font-weight: 500;


}

button:disabled {
  cursor: not-allowed;
}

ul, ol {
    list-style: none;
}

a {
    text-decoration: none;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: initial;
}

body.no-scroll {
  overflow: hidden;
  touch-action: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

